import React from "react";
import SEO from "@components/seo";
import Layout from "@components/layout";
import Footer from "@components/footers/dark";
import MenuBar from "@components/menuBar/MenuBar";

const TermsPage = () => (
  <>
    <SEO title="Privacy Policy" />
    <MenuBar/>
    <Layout>
      <Content/>
    </Layout>
    <Footer/>
  </>
);

const Content = () => (
  <div className="relative py-16 bg-white overflow-hidden">
    <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
      <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
        <svg className="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
        </svg>
        <svg className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
        </svg>
        <svg className="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
        </svg>
      </div>
    </div>
    <div className="relative px-4 sm:px-6 lg:px-8">
      <div className="text-lg max-w-prose mx-auto">
        <h1>
          <span className="block text-base text-center text-highlight font-semibold tracking-wide uppercase">Waivpay</span>
          <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Privacy Policy</span>
        </h1>
      </div>
      <div className="mt-6 prose prose-lg text-gray-500 mx-auto">
        {/* <h2>Purpose</h2> */}
        <p>
          WAIVPAY is committed to respecting the privacy of all employees, customers and vendors, and complying with all applicable privacy laws.
        </p>
        <p>
          These are set out in:
        </p>
        <ul>
          <li>
            The Privacy Act 1988, which contains the Australian Privacy Principles (APPs) and in relevant privacy codes. See https://www.oaic.gov.au/privacy/australian-privacy-principles/read-the-australian-privacy-principles/
          </li>
        </ul>
        <p>
          This Policy is intended to adopt the highest applicable privacy laws in Australia and outlines the circumstances surrounding the management of personal information in accordance with privacy laws.
        </p>
        <h2>Application of Policy</h2>
        <p>
          This Policy applies to all WAIVPAY dealings with individuals, including employees (past and present) and prospective employees. This Policy does not form part of any employee’s contract of employment.
        </p>
        <h2>Guiding Principles</h2>
        <p>
          WAIVPAY adopts a privacy by design approach and takes and ensure we take all reasonable steps to protect the personal information we collect or hold.
        </p>
        <ol>
          <li>
            We only collect personal information that is necessary for the function or activity of our customer facing products and platforms
          </li>
          <li>
            Ensure our procedures and systems are compliant with the APPs including complaints handling.
          </li>
          <li>
            Make available an up-to-date and clear privacy policy specific to each application.
          </li>
          <li>
            Take all reasonable steps to ensure that personal information collected is accurate, complete and up to date.
          </li>
          <li>
            Provide individuals access to or correct their personal information on request.
          </li>
          <li>
            Where possible de-identify personal information
          </li>
          <li>
            Delete unsolicited personal information as soon as is practical, if it is not necessary for the function or activity of our customer facing products and platforms
          </li>
          <li>
            We do not use any personal information other than the original purpose of its collection.
          </li>
        </ol>
        <p>
          WAIVPAY has a privacy working group which consists of key business representatives and the CEO who meet quarterly or as needed to oversee and ensure all policies are up to date and clearly communicated across the company.
        </p>
        <h2>
          What is Personal Information?
        </h2>
        <p>
          <strong>Personal information</strong> is information or an opinion about an identified individual, or an individual who is identifiable:
        </p>
        <ul>
          <li>
            whether the information or opinion is true or not; and
          </li>
          <li>
            whether the information or opinion is recorded in a material form or not (i.e. digitally or hard copy).
          </li>
        </ul>
        <p>
          <strong>Sensitive information</strong> is a special category of personal information includes information or an opinion about a person’s health, race or ethnic origin, political, religious or philosophical beliefs, membership of a trade union or association, criminal record, sex life, sexual orientation and genetic and ‘biometric’ information.
        </p>
        <p>
          We also treat credit card or debit card information as sensitive and do not store any credit card or debit card details
        </p>
        <p>
          We will only collect a person’s sensitive information where it is reasonably necessary for our business activities and with the person’s consent.
        </p>
        <h2>
          How does WAIVPAY Classify the data we process or store?
        </h2>
        <p>
          WAIVPAY classify data into four categories that determine the processing, handling, storage and use of the data and the associated protection controls and systems.
        </p>
        <table>
          <tr>
            <th className="p-4 pl-0">Classification</th>
            <th className="p-4 pl-0">Description</th>
          </tr>
          <tr>
            <td className="align-top">
              Sensitive
            </td>
            <td className="align-top">
              Data that is defined by under the APP and examples include:
              <ul>
                <li>
                  health (including predictive genetic information)
                </li>
                <li>
                  racial or ethnic origin
                </li>
                <li>
                  political opinions
                </li>
                <li>
                  membership of a political association, professional or trade association or trade union
                </li>
                <li>
                  religious beliefs or affiliations
                </li>
                <li>
                  philosophical beliefs
                </li>
                <li>
                  sexual orientation or practices
                </li>
                <li>
                  criminal record
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="align-top">
              Personal</td>
            <td className="align-top">
              Information that can be used to identify a data subject, either directly or in combination with other data or information.
              <p>
                Common examples are an individual’s name, signature, address, telephone number, date of birth, email address and commentary or opinion about a person.
              </p>
            </td>
          </tr>
          <tr>
            <td className="align-top">
              Confidential
            </td>
            <td className="align-top">
              Business data, terms, performance, accounting commercials by either party that should not to expose to other entities.
            </td>
          </tr>
          <tr>
            <td className="align-top">
              Normal
            </td>
            <td className="align-top">
              General data that does not belong to any other categories.
            </td>
          </tr>
        </table>
        <h2>
          For what purposes do we use personal information?
        </h2>
        <p>
          WAIVPAY only uses personal information which is reasonably necessary for our dealings with the relevant individual in the course of our business.
        </p>
        <p>
          In general, we use personal information for the following purposes:
        </p>
        <ul>
          <li>
            providing products or services that have been requested
          </li>
          <li>
            communicating with the individual in fulfillment of the product or service
          </li>
          <li>
            provide support for our product and services
          </li>
          <li>
            complying with regulatory and legal obligations; or
          </li>
        </ul>
        <p>
          In addition to the above purposes, we may also use personal information where an individual has consented to one or more specific purposes, or as necessary for compliance with our legal obligations, or otherwise for a legitimate purpose that is not overridden by the individual’s fundamental rights and freedoms.
          This includes accessing the contact list within an individual’s mobile device to pre-fill recipient data as part of the digital purchase form. This data is not stored or used in any other way.
        </p>
        <h2>
          What kinds of personal information do we collect?
        </h2>
        <p>
          The kinds of personal information that WAIVPAY collect will depend upon the specific product or service the relevant individual may have with us. We may collect information about:
        </p>
        <ul>
          <li>
            purchasers or potential purchasers of our products
          </li>
          <li>
            suppliers (for example, when establishing records and systems to enable payment for goods or services);
          </li>
          <li>
            job applicants (for the purposes of employment)
          </li>
          <li>
            employees or contractor records
          </li>
        </ul>
        <p>
          The kinds of personal information may include, but are not limited to:
        </p>
        <ul>
          <li>
            information that identifies purchasers such as name, address, delivery details, email address and any other information required to enable fulfillment of the product or service
          </li>
          <li>
            information about a person that is required or authorised by law
          </li>
        </ul>
        <h2>
          How does we collect personal information?
        </h2>
        <p>
          We primarily collect information directly from individuals. However, in some cases, we may receive that information from other sources, such as a third party who discloses the information to us in connection with providing a product or service requested by the individual.
        </p>
        <p>
          If we collect personal information directly from an individual, we will notify the individual, at the time of collection, of the following information:
        </p>
        <ul>
          <li>
            Our identity and the contact details of our representative who handles privacy related enquiries and requests.
          </li>
          <li>
            the facts and circumstances of the collection;
          </li>
          <li>
            the purposes of collection;
          </li>
          <li>
            the length of time we will keep the information;
          </li>
          <li>
            the consequences if personal information is not collected;
          </li>
          <li>
            how and to whom else we may disclose that personal information;
          </li>
          <li>
            information about our Privacy Policy, including an individual’s right to access and seek correction of personal information we have about them; and
          </li>
        </ul>
        <p>
          Where personal information has been collected indirectly (i.e. from a third party), we will notify the individual of the above matters as soon as reasonably practicable, unless doing so would be impossible or involve a disproportionate effort. In addition, we will inform the individual of the source from which we obtained the information.
        </p>
        <p>
          To the extent practicable, we will also provide individuals with the option of deidentifying themselves or of using a pseudonym when dealing with us.
        </p>
        <h2>
          Direct marketing
        </h2>
        <p>
          WAIVPAY will provide a simple means by which individuals may opt-out of any direct marketing communications undertaken by WAIVPAY. WAIVPAY does not direct market to any purchaser’s or potential purchaser’s for products or services unless provided directly to the purchaser or potential purchaser by WAIVPAY Employees
        </p>
        <h2>
          Job applicants
        </h2>
        <p>
          WAIVPAY may collect personal information from job applicants in the course of the recruitment process.
        </p>
        <h2>
          Access to employment records
        </h2>
        <p>
          The Privacy Act, Australia gives employees the right to access certain statutory employment records, including:
        </p>
        <ul>
          <li>
            their employment agreement
          </li>
          <li>
            their time and wages records their leave records
          </li>
        </ul>
        <h2>
          Consent and withdrawal of consent
        </h2>
        <p>
          Where WAIVPAY relies on an individual’s consent to lawfully collect and use their personal information, that consent must be explicit (i.e. not implied), freely given, informed and specific (i.e. distinguishable from consent for other matters or purposes).
        </p>
        <p>
          Individuals may withdraw their consent at any time by notifying us. We will then delete the individual’s Personal Information unless we have another legitimate basis to use that information. Withdrawal of consent may mean that we will no longer be able to offer the same products and services to the individual.
        </p>
        <h2>
          How long is Personal Information kept?
        </h2>
        <p>
          WAIVPAY keeps personal information only as long as necessary to fulfil the purpose for which it was collected. When we no longer need it for that purpose, we will destroy the information or ensure that the information is de-identified (i.e. it will no longer be possible to connect the information to the individual).
        </p>
        <p>
          Generally speaking, we keep employee records for seven years or as required by law.
        </p>
        <h2>
          Overseas recipients of Personal Information
        </h2>
        <p>
          WAIVPAY does not disclose or release any personal information to entities or organisations in other countries (Overseas Recipients).
        </p>
        <h2>
          Right to access and correct
        </h2>
        <p>
          WAIVPAY takes all reasonable steps to ensure that the personal information it uses is accurate, up-to-date and complete. However, where this is not the case, individuals have a number of rights they may exercise:
        </p>
        <p>
          Individuals have the right to:
        </p>
        <ul>
          <li>
            access their personal information held by WAIVPAY
          </li>
          <li>
            access information about where and for what purpose we have used their information
          </li>
          <li>
            correct any incorrect information
          </li>
        </ul>
        <p>
          If an individual makes a request for access to or correction of their personal information, we will respond within a reasonable period and, if reasonable and practicable to do so, we will:
        </p>
        <ul>
          <li>
            provide the information, unless we consider that there is a sound and lawful reason to withhold it;
          </li>
          <li>
            correct the information, if we are satisfied the information we hold is incorrect.
          </li>
        </ul>
        <p>
          If we refuse to give access to or correct personal information as requested by an individual, we will explain our decision to the individual and advise them of mechanisms available to them to complain about that refusal.
        </p>
        <h2>
          Right to be forgotten
        </h2>
        <p>
          Individuals have the right to have their personal information erased in certain circumstances, including where the information is no longer relevant to the original purpose for which it was collected or where the individual withdraws their consent.
        </p>
        <h2>
          Right to object to or restrict use
        </h2>
        <p>
          Where WAIVPAY uses personal information without the individual’s consent (but pursuant to another legitimate purpose), the individual has the right to object to or restrict such use of their personal information.
        </p>
        <h2>
          Data breach notification
        </h2>
        <p>
          In the event of a data breach that is likely to result in serious harm to the individual, we will comply with mandatory data breach notification laws. This includes notification to the affected individual and, if the breach is in Australia, also to the Australian Information Commissioner.  Please refer to our Data Breach Response Plan.
        </p>
        <h2>
          Variations
        </h2>
        <p>
          We reserve the right to vary, replace or terminate this policy from time to time.
        </p>
      </div>
    </div>
  </div>
);

export default TermsPage;

